.label {
    height: 48px;
    display: grid;
    grid-template-columns: 10% 15% 45% 10% 10% 10%;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "l1 l2 l3 l4 l5 l6";
    font-family: 'Poppins', sans-serif;
}

.l1 {
    grid-area: l1;
}

.l2 {
    grid-area: l2;
}

.l3 {
    grid-area: l3;
}

.l4 {
    grid-area: l4;
}

.l5 {
    grid-area: l5;
}

.l6 {
    grid-area: l6;
}

.sublabel {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.text-label {
    font-size: 18px;
    color: #535c68;
}

.icon-label {
    height: 18px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .label{
        height: 38px;
        grid-template-columns: 10% 20% 40% 10% 10% 10%;
    }

    .text-label{
        font-size: 16px;
    }

    .icon-label {
        height: 16px;
    }

}

@media screen and (max-width: 768px) {
    .label{
        height: 32px;
        grid-template-columns: 10% 20% 40% 10% 10% 10%;
    }

    .text-label{
        font-size: 14px;
    }

    .icon-label {
        height: 14px;
    }
}
