.playlist {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.loading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-img{
    width: 50px;
}

.error{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.text-error {
    font-size: 18px;
    color: #535c68;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .playlist {
        width: 90%;
    }

    .text-error{
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .playlist {
        width: 95%;
    }

    .text-error{
        font-size: 14px;
    }
}
