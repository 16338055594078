.header{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
}

.container-title{
    margin: 52px 0;
    font-size: 28px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title{
    font-size: 62px;
    text-transform: uppercase;
    color: #30336b;
    margin: 0;
    font-weight: 600;
}

.subtitle{
    font-size: 28px;
    color:#535c68;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .header{
        width: 90%;
    }

    .container-title{
        margin: 42px 0;
    }

    .title {
        font-size: 52px;
    }

    .subtitle{
        font-size: 22px;
    }
}

@media screen and (max-width: 768px) {
    .header{
        width: 95%;
    }

    .container-title{
        margin: 32px 0;
    }

    .title {
        font-size: 26px;
    }

    .subtitle{
        font-size: 16px;
    }
}
