.menu{
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    border-bottom: 1px solid #535c68;
}

.item-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-menu{
    font-size: 18px;
    color: #535c68;
    margin-right: 8px;
}

.select-menu{
    font-size: 18px;
    color: #535c68;
    background: #ecf0f1;
    border-radius: 5px;
    outline: 0;
    height: 28px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .menu{
        height: 38px;
    }

    .text-menu{
        font-size: 16px;
    }

    .select-menu{
        font-size: 16px;
    }

    .select-menu{
        height: 26px;
    }
}

@media screen and (max-width: 768px) {
    .menu{
        height: 32px;
    }

    .text-menu{
        font-size: 14px;
    }

    .select-menu{
        font-size: 14px;
    }

    .select-menu{
        height: 24px;
    }
}